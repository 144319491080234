.landingPageComponent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.landingPageComponentContent {
    max-width: var(--breakpoint-screen--x-lg);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-left: 40px;
    margin-right: 40px;
}

@media (max-width: 1024px) {
    .landingPageComponent {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .landingPageComponentContent {
        max-width: var(--breakpoint-screen--lg);
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media (max-width: 960px) {
    .landingPageComponent {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .landingPageComponentContent {
        max-width: var(--breakpoint-screen--md);
        margin-left: 40px;
        margin-right: 40px;
    }
}

@media (max-width: 768px) {
    .landingPageComponent {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .landingPageComponentContent {
        max-width: var(--breakpoint-screen--sm);
        margin-left: 16px;
        margin-right: 16px;
    }
}

@media (max-width: 528px) {
    .landingPageComponent {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .landingPageComponentContent {
        max-width: var(--breakpoint-screen--x-sm);
        margin-left: 16px;
        margin-right: 16px;
    }
}
