
.hero {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
}

.heroImageBackground {
    z-index: -1;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    background-image: url('https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/perks/images/Blog-full.svg');
    top: 0;
    height: 100%;
    width: 100%;
    min-width: 1380px;
}

.heroContentCentering {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.heroContentWrapper {
    max-width: 1380px;
    width: calc(100% - 80px);
    display: flex;
    justify-content: start;
}

.coinsImage {
    align-self: start;
    display: none;
}

.microCopy {
    height: var(--spacing--md);
    color: var(--color-brand--primary);
    font-weight: var(--font-weight--bold);
    font-size: 14px;
    line-height: var(--spacing--md);
    letter-spacing: 0.43em;
    margin-bottom: var(--spacing--sm);
    margin-top: var(--spacing--md);
}

.title {
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--30);
    margin-bottom: var(--spacing--md);
}

.loggedOutActions {
    margin-top: var(--spacing--md);
}

.loggedInActions {
    margin-top: var(--spacing--md);
}

.loggedInActiveActions {
    margin-top: var(--spacing--md);
}

.primaryActionButton {
    margin-bottom: var(--spacing--md);
}

.heroLink {
    color: var(--color-gray--60) !important;

}

.heroLinkActive {
    color: var(--color-gray--60) !important;
}


@media (min-width: 768px) {
    .hero {
        min-height: 33rem;
    }

    .heroImageBackground {
        height: 33rem;
    }

    .heroContent {
        min-width: 200px;
    }

    .title {
        font-size: var(--font-size--42);
    }

    .coinsImage {
        display: block;
    }

    .microCopy {
        margin-top: var(--spacing--lg);
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .coinsImage {
        display: block;
    }

    .heroContent {
        min-width: 450px;
    }
}

@media (min-width: 1024px) {
    .hero {
        min-height: 34rem;
    }

    .heroContent {
        min-width: 740px;
    }

    .heroImageBackground {
        height: 34rem;
    }

    .coinsImage {
        display: block;
    }

    .microCopy {
        margin-top: var(--spacing--x-lg);
        font-weight: var(--font-weight--bold);
        font-size: var(--font-size--16);
        text-transform: uppercase;
        line-height: var(--spacing--lg);
        letter-spacing: var(--spacing--sm);
    }

    .title {
        font-size: 82px;
        line-height: 84px;
        color: var(--color-gray--50);
        width: 100%;
    }
}
