.redemptionLocations {
    composes: landingPageComponent from "../../css/landing-page-component.module.css";
    flex-direction: row;
    margin-bottom: 30px;
    margin-top: 30px;
}

.redemptionLocationsContent {
    composes: landingPageComponentContent from "../../css/landing-page-component.module.css";
    padding: 26px;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: var(--color-gray--10);
}

.redemptionLocationsPrimaryPartner {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.redemptionLocationsPrimaryPartnerLogo {
}

.redemptionLocationsPrimaryPartnerInfo {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-left: 40px;
}

.redemptionLocationsPrimaryPartnerInfoHeader {
    margin-bottom: 16px;
}

.redemptionLocationsPrimaryPartnerInfoBody {
    margin-bottom: 12px;
}

.redemptionLocationsExternalLocations {
    border-top-color: var(--color-gray--30);
    border-top-width: 1px;
    border-top-style: solid;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.redemptionLocationsExternalLocationsHeader {
    margin-bottom: 14px;
}

.redemptionLocationsExternalLocationsPartners {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.redemptionLocationsExternalLocationsPartner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.redemptionLocationsExternalLocationsPartner:not(:first-child) {
    margin-left: 40px;
}

.redemptionLocationsExternalLocationsPartnerLogoWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.redemptionLocationsExternalLocationsPartnerLogo {
}

.redemptionLocationsExternalLocationsPartnerLink {
}

.redemptionLocationsExternalLocationsPartnerAction {
    cursor: pointer;
}

@media (max-width: 875px) {
    .redemptionLocationsPrimaryPartner {
        flex-direction: column;
    }

    .redemptionLocationsPrimaryPartnerLogo {
        max-width: 70%;
        margin-bottom: 16px;
        align-self: center;
    }

    .redemptionLocationsPrimaryPartnerInfo {
        margin-left: 0;
    }

    .redemptionLocationsExternalLocationsPartners {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%
    }

    .redemptionLocationsExternalLocationsPartner:not(:first-child) {
        margin-left: 0;
    }
}

@media (max-width: 528px) {
    .redemptionLocationsExternalLocationsPartners {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .redemptionLocationsExternalLocationsPartner:not(:first-child) {
        margin-left: 0;
        margin-top: 20px;
    }
}
