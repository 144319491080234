.ctaWrap {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    max-width: 1260px;
    gap: 32px;
    background: var( --color-gray--10);
    border-radius: 4px;
    padding: 24px 10px 24px 10px;
}

.contentWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    max-width: 1024px;
    overflow: scroll;
}

.mainContent {
    display: flex;
    margin-top: 25px;
}

.headerWrap {
    display: flex;
    flex-direction: row;
}

.header {
    width: 529px;
    height: 32px;
    font-style: normal;
    font-weight: var(--font-weight--light);
    font-size: var(--font-size--28);
    line-height: 115%;
    color: var(--color-gray--50);

}

.subHeader {
    font-style: normal;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--16);
    line-height: 150%;
    color: var(--color-gray--50);
}

.body {
    font-style: normal;
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--14);
    line-height: 150%;
    color: var(--color-gray--50);
}

.ctaLink {
    color: var(--color-gray--60) !important;
}

.infoSection {
    width: 298.67px;
    height: 108px;
    padding: 0;
    display: block;
}

.primaryAction {
    background: var(--color-brand--primary);
    border-radius: 3px;
    margin-left: 20px;
}

.verticalLine {
    margin-right: 20px;
    margin-left: 10px;
    height: 128px;
}

@media (max-width: 900px) {
    .ctaWrap {
        max-width: 820px;
        align-items: flex-start;
    }

    .logo {
        max-width: 125px;
        max-height: 125px;
        margin: 0px auto auto auto;
    }

    .mainContent {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
    }

    .headerWrap {
        flex-direction: column;
    }

    .header {
        width: 523px;
        height: 25px;
        font-style: normal;
        font-weight: var(--font-weight--light);
        font-size: var(--font-size--22);
        line-height: 115%;
        color: var( --color-gray--50);
    }

    .primaryAction {
        background: #E21C11;
        border-radius: 3px;
        margin-left: 0px;
        margin-top: 10px;
    }

    .infoSection {
        width: 523px;
        height: 90px;
    }

    .verticalLine {
        height: 0px;
        width: 523px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (max-width: 478px) {
    .ctaWrap {
        flex-direction: column;
        width: 340px;
        align-items: center;
    }

    .logo {
        max-width: 125px;
        max-height: 125px;
    }

    .headerWrap {
        flex-direction: column;
    }

    .header {
        width: 295px;
        height: 50px;
    }

    .primaryAction {
        display: block;
    }

    .infoSection {
        width: 290px;
        height: 50%;
    }

    .verticalLine {
        height: 0px;
        width: 295px;
        margin-left: 0px;
        margin-right: 0px;
    }
}
