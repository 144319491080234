.page {

}

.page section {
    border: none !important;
}

.page a {
    font-weight: var(--font-weight--semi-bold);
    color: var(--color-brand--primary);
    text-decoration: none;
}

.page a:hover {
    color: var(--color-brand--hover);
    text-decoration: underline;
}

.page strong {
    font-weight: var(--font-weight--semi-bold);
}
